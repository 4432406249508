import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { Websocket } from 'components/websocket/Websocket'
import { WsStatus } from 'components/websocket-status/WsStatus'
import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { wsTopics } from 'httpServices/wsTopics'
import { useState } from 'react'
import { addBirthday } from 'redux/birthday/BirthdaySlice'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import { updatePendingOrder } from 'redux/order/orderSlice'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import styled from 'styled-components'
import { SPACING } from 'styles/tokens'
import { BirthdayBookingDetailsDTO, ComplaintLightDTO, OrderLightDTO } from 'types/api'
import { Permissions } from 'types/api.enum'
import { FEATURE_TOGGLE_CODE, LocalStorageKey, StatusType } from 'utils/enums'
import { hasPermission } from 'utils/util'

import { Dashboard } from './components/dashboard/Dashboard'
import { PowerBIBanner } from './components/powerbi-banner/PowerBiBanner'

export const Home = () => {
    const dispatch = useAppDispatch()
    const [wsStatus, setWsStatus] = useState<StatusType>(StatusType.ERROR)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const { email } = useAppSelector((state) => state.user)
    const ordersPermissions = hasPermission(userPermissions, Permissions.SHOW_ORDER)
    const complaintsPermissions = hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)
    const hasOrderOrComplaintPermission = ordersPermissions || complaintsPermissions

    const ordersTopic = wsTopics.ordersByRestaurant(restaurantFrNumber)
    const complaintsTopic = wsTopics.complaintsByRestaurant(restaurantFrNumber)
    const birthdaysTopic = wsTopics.birthdayBookingsByRestaurant(restaurantFrNumber)
    const topics = [
        ...(ordersPermissions ? [ordersTopic] : []),
        ...(complaintsPermissions ? [complaintsTopic] : []),
        birthdaysTopic,
    ]

    const powerBiEnumKey = `${LocalStorageKey.SAW_POWER_BI_BANNER}_${email}`

    const powerBiEnabled = useFeatureToggle([FEATURE_TOGGLE_CODE.DASHBOARD_POWER_BI])
    const alreadySawPowerBiBanner = localStorage.getItem(powerBiEnumKey) === 'true'

    const [displayPowerBiBanner, setDisplayPowerBiBanner] = useState(
        alreadySawPowerBiBanner ? !alreadySawPowerBiBanner : true,
    )

    const handleClick = () => {
        localStorage.setItem(powerBiEnumKey, 'true')
        setDisplayPowerBiBanner(false)
    }

    return (
        <StyledPageContainer>
            {powerBiEnabled &&
                displayPowerBiBanner &&
                hasPermission(userPermissions, Permissions.SHOW_POWER_BI_DASHBOARD) && (
                    <StyledBannerContainer hasExtraBottomMargin>
                        <PowerBIBanner onClick={handleClick} />
                    </StyledBannerContainer>
                )}

            <ShouldDisplay condition={hasOrderOrComplaintPermission}>
                <WsStatus status={wsStatus} />
                <Websocket
                    topics={topics}
                    onMessage={(message, topic) => {
                        if (topic === ordersTopic) {
                            dispatch(updatePendingOrder(message as OrderLightDTO))
                        } else if (topic === complaintsTopic) {
                            dispatch(updateComplaint(message as ComplaintLightDTO))
                        } else if (topic === birthdaysTopic) {
                            dispatch(addBirthday(message as BirthdayBookingDetailsDTO))
                        }
                    }}
                    onConnect={() => setWsStatus(StatusType.FINISHED)}
                    onConnectFailure={() => setWsStatus(StatusType.ERROR)}
                    onDisconnect={() => setWsStatus(StatusType.ERROR)}
                />
            </ShouldDisplay>

            <Dashboard />
        </StyledPageContainer>
    )
}

const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

const StyledBannerContainer = styled.div<{ hasExtraBottomMargin?: boolean }>`
    margin: -${SPACING.L} -${SPACING.L} ${(props) => (props.hasExtraBottomMargin ? SPACING.L : `0px`)} -22px;
    width: calc(100% + 46px);
    z-index: 1;
`
