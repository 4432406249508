import { AppBar } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'

export enum BannerPositionVariant {
    FIXED = 'fixed',
    ABSOLUTE = 'absolute',
    RELATIVE = 'relative',
    STICKY = 'sticky',
}

interface BannerProps {
    children?: ReactNode
    position?: BannerPositionVariant
    hasLinearBackground?: boolean
    backgroundColor?: string
    borderRadius?: string
    isBottomTabBar?: boolean
}

export const Banner = ({
    children,
    position = BannerPositionVariant.FIXED,
    backgroundColor = colors.red,
    hasLinearBackground = false,
    isBottomTabBar = false,
    borderRadius,
}: BannerProps) => {
    return (
        <AppBar
            position={position}
            sx={{
                boxShadow: 'none',
                ...(hasLinearBackground
                    ? {
                          background: `linear-gradient(177deg, rgba(180, 50, 59, 1) 0%, rgba(218, 92, 77, 1) 46%, rgba(228, 92, 76, 1) 100%)`,
                      }
                    : {
                          backgroundColor,
                      }),
                ...(isBottomTabBar
                    ? {
                          bottom: 0,
                          top: 'auto',
                          paddingRight: '60px',
                          borderRadius: `${borderRadius} ${borderRadius} 0 0`,
                      }
                    : {
                          borderRadius,
                          top: 0,
                      }),
            }}
        >
            {children && <StyledContentContainer>{children}</StyledContentContainer>}
        </AppBar>
    )
}

const StyledContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.L};
    align-items: center;
    flex: 1;
    padding: ${SPACING.M} ${SPACING.M} ${SPACING.M} ${SPACING.XXL};
`
