import {
    ComplaintClosureReason,
    ComplaintReportReason,
    ComplaintStatus,
    ComplaintType,
    DayLabel,
    DeliveryStatus,
    ErrorBirthday,
    FraudScoring,
    OrbStatus,
    PickUpType,
    RefundType,
    Responsibility,
    Role,
    ServiceCode,
} from 'types/api.enum'
import { AccordionDetailsKey, StatusType, TutorialTab } from 'utils/enums'

export const fr = {
    translation: {
        burgerKingFrance: 'Burger King® France',
        burgerKing: 'Burger King',
        component: {
            button: {
                validate: 'Valider',
                cancel: 'Annuler',
                login: 'Se connecter',
                logout: 'Se déconnecter',
                more: 'Voir plus',
                refresh: 'Rafraîchir la page',
                refreshContent: 'Rafraîchir le contenu',
                integrateOrder: 'Commande intégrée manuellement',
                on: 'Actif',
                off: 'Inactif',
                refund: 'Valider le remboursement',
                contest: 'Contester',
                confirm: 'Confirmer',
                answer: 'Proposer un dédommagement',
                terminate: 'Clôturer la réclamation',
                home: "Revenir à la page d'accueil",
                seeError: "Voir l'erreur",
                send: 'Envoyer',
                understood: "Ok j'ai compris",
                closeComplaint: 'Clôturer la réclamation',
            },
            dropdown: {
                reportComplaint: 'Signaler la réclamation au service consommateur',
            },
            timeSelector: {
                modal: {
                    title: 'Modifier le temps de préparation',
                    textAbove:
                        'Merci de renseigner le temps nécessaire pour préparer vos commandes. Nous indiquerons aux livreurs et clients de venir récupérer leurs commandes après la durée indiquée.',
                    textBelow: 'Vous disposerez de {{number}} min pour préparer toute nouvelle commande',
                },
                timePreparation: 'Préparation : ',
                changePreparationTime: 'Modifier le temps de préparation',
            },
        },
        page: {
            home: {
                route: '/',
                title: 'Accueil',
                orders: {
                    errorOrders: "Commandes en erreur d'intégration",
                    deliveryOrders: 'Commandes Livraison',
                    CCOrders: 'Commandes Click & Collect',
                    kingTableOrders: 'Commandes King Table',
                    shopComplaints: 'Réclamations restaurant',
                    receivedOrders_one: 'Commande reçue',
                    receivedOrders_other: 'Commandes reçues',
                    completeOrders_one: 'Commande terminée',
                    completeOrders_other: 'Commandes terminées',
                    currentOrders_one: 'Commande en cours',
                    currentOrders_other: 'Commandes en cours',
                    toCheckComplaints_one: 'Réclamation à vérifier',
                    toCheckComplaints_other: 'Réclamations à vérifier',
                    toPayBackComplaints_one: 'Réclamation à rembourser',
                    toPayBackComplaints_other: 'Réclamations à rembourser',
                },
                dashboard: {
                    orders: {
                        deliveryOrders: 'Commandes - King Delivery',
                        CCOrders: 'Commandes - Click & Collect',
                        kingTableOrders: 'Commandes - King Table',
                        receivedOrders_one: 'Reçue',
                        receivedOrders_other: 'Reçues',
                        completeOrders_one: 'Terminée',
                        completeOrders_other: 'Terminées',
                        currentOrders_one: 'En cours',
                        currentOrders_other: 'En cours',
                    },
                    complaints: {
                        complaintsToCheck: 'Réclamations restaurant',
                        checkedComplaints: 'Réclamations traitées',
                        toCheckComplaints_one: 'À vérifier',
                        toCheckComplaints_other: 'À vérifier',
                        toPayBackComplaints_one: 'À rembourser',
                        toPayBackComplaints_other: 'À rembourser',
                        waitingConsoService_one: 'En attente service conso',
                        waitingConsoService_other: 'En attente service conso',
                        payedBack_one: 'Remboursée',
                        payedBack_other: 'Remboursées',
                    },
                    services: {
                        errorOrders: 'Commandes en erreur',
                        averageError: "Taux d'erreur moyen",
                        upcomingBirthday: 'Anniversaires à venir',
                        restaurantServices: 'Services restaurant',
                        serviceCC: 'Click & Collect',
                        serviceDelivery: 'King Delivery',
                        serviceKingTable: 'King Table',
                        serviceParking: 'Click & Parking',
                        serviceDrive: 'Click & Drive',
                        active: 'Actif',
                        inactive: 'Inactif',
                        noSchedule: 'Horaires indisponibles',
                        schedule: '{{opening}} - {{closing}}',
                        percentage: '{{number}} %',
                    },
                    banner: {
                        title: 'NOUVEAUTE !',
                        description:
                            'Vous pouvez désormais découvir toutes <0>les statistiques de réclamations </0> de vos restaurants',
                    },
                    usefulLinks: {
                        usefulLink: 'Liens utiles',
                        technicalSupport: {
                            title: 'Centre de support technique',
                            description: '01 70 94 56 10  (de 7h à 22h, 7j/7)',
                        },
                        customerService: {
                            title: 'Service consommateur',
                            description: 'Gestion des réclamations clients : 08 00 97 80 80',
                        },
                        digitalCustomer: {
                            title: 'Digital Clients',
                            description: 'Problématiques restaurants (offre, BOB, Kingdom...)',
                        },
                        tutoBob: {
                            title: 'Tuto BOB',
                            description: 'Comment ça marche ?',
                        },
                    },
                },
                dropDownPlaceHolder: 'Rechercher par ville ou nom',
                error: 'Nous sommes incapable de vous afficher cette page pour le moment. Veuillez vérifier que votre appareil est bien connecté à une connexion internet stable et réessayer plus tard.',
            },
            orders: {
                route: '/commandes',
                routeDetail: 'commandes/:orderId',
                title: 'Commandes',
                tabs: {
                    current: 'En cours',
                    end: 'Terminées',
                    error: 'En erreur',
                    all: 'Reçues',
                },
            },
            services: {
                route: '/services',
                title: 'Services',
            },
            complaints: {
                route: '/reclamations',
                routeDetail: 'reclamations/:complaintId',
                title: 'Réclamations',
                tabs: {
                    current: 'À traiter',
                    end: 'Traitées',
                },
                button: 'Statistiques réclamations',
            },
            birthday: {
                route: '/services/birthday',
                routeDetail: 'services/birthday/:birthdayId',
            },
            kingTable: {
                route: '/services/king-table',
            },
            parameters: {
                route: '/parametres',
                title: 'Paramètres',
                info: 'Restaurant Manager©\nBurger King France - {{year}}\nVersion {{version}}',
                infoAccount: 'Vous êtes connecté avec {{email}} en tant que {{role}}',
                updatePassword: 'Modifier mon mot de passe',
                mail: 'Email',
                manageComplaint: {
                    name: 'Gestion des réclamations',
                    activate: 'Laissez la possiblité de gérer les réclamations pour votre restaurant',
                },
                manageRefund: {
                    name: 'Gestion des remboursements',
                    activate: 'Laissez la possiblité de gérer les remboursements pour votre restaurant',
                },
                receiveComplaint: {
                    activate: `Recevez un email lors d'une réclamation "Á valider" ou "Á rembourser"`,
                },
                receiveRefund: {
                    activate: "Recevez un email lors d'un remboursement",
                },
            },
            login: {
                route: '/connexion',
            },
            setPassword: {
                route: '/nouveau-mot-de-passe',
            },
            forgottenPassword: {
                route: '/mot-de-passe-oublie',
            },
            collaborators: {
                route: '/collaborateurs',
                routeDetail: '/collaborateurs/:id',
                title: 'Collaborateurs',
                employeePromotion: {
                    title: 'Promotion collaborateur',
                    description: 'Ajouter un collaborateur pour le faire bénéficier du blason -20%',
                    cta: {
                        addEmployee: 'Ajouter un collaborateur',
                        updateEmployee: 'Modifier',
                        detailsEmployee: 'Voir plus',
                        resendMail: 'Renvoyer un mail',
                    },
                    employeeList: {
                        lastName: 'Nom',
                        firstName: 'Prénom',
                        mail: 'Email',
                        id: 'ID unique',
                        employeeId: 'ID collaborateur',
                        restaurantName: 'Restaurant associé',
                        code: 'Code coupon',
                    },
                },
                addEmployee: {
                    route: '/collaborateurs/ajouter-un-collaborateur',
                    title: 'Ajouter un collaborateur',
                    cta: 'Enregistrer',
                },
                deleteModal: {
                    title: 'Êtes-vous sûr de vouloir supprimer ce collaborateur ?',
                    error: {
                        other: "Oups, une erreur s'est produite. Veuillez réessayer dans un moment.",
                        conflict: 'Oups, cette adresse e-mail est déjà reliée a un compte.',
                    },
                },
                updateEmployee: {
                    title: 'Modifier le collaborateur',
                    successResendMail: 'Mail renvoyé avec succès',
                    errorResendMail:
                        'Une erreur inattendue est survenue. Impossible de modifier le service pour le moment',
                },
                detailsEmployee: {
                    title: 'Détail collaborateur',
                },
            },
            statistics: {
                route: '/statistiques',
                title: 'Statistiques',
            },
        },
        days: {
            [DayLabel.MONDAY]: 'Lundi',
            [DayLabel.TUESDAY]: 'Mardi',
            [DayLabel.WEDNESDAY]: 'Mercredi',
            [DayLabel.THURSDAY]: 'Jeudi',
            [DayLabel.FRIDAY]: 'Vendredi',
            [DayLabel.SATURDAY]: 'Samedi',
            [DayLabel.SUNDAY]: 'Dimanche',
        },
        filters: {
            date: 'Filtrer par date :',
        },
        role: {
            [Role.ADMINISTRATOR]: 'Administrateur',
            [Role.DIRECTOR]: 'Directeur',
            [Role.FRANCHISE]: 'Franchise',
            [Role.RESTAURANT]: 'Restaurant',
            [Role.RDM]: 'Responsable de marché',
        },
        complaints: {
            tutorial: {
                banner: {
                    title: 'Cette réclamation vous engage: Communiquez avec le client si besoin, choisissez un dédommagement ou clôturer la réclamation.',
                    restaurant:
                        'Cette réclamation vous engage, vous pouvez échanger avec le client, choisir un dédommagement ou clôturer la réclamation.',
                    delivery: {
                        waitingRestaurant:
                            'Cette réclamation engage votre responsabilité: Valider ou contester cette réclamation qui sera ensuite étudié par le service consommation.',
                        toRefund:
                            'Le service consommateur à estimé que la responsabilité de cette réclamation vous engage. Vous devez valider le remboursement.',
                        waitingSupport:
                            'Vous avez contesté cette décision. Le service consommateur doit maintenant prendre une décision sous 72h.',
                        refund: 'Cette réclamation est en cours de remboursement. Vous n’avez rien à faire',
                    },
                    link: 'Comment ça marche ?',
                },
            },
            beginContestModal: {
                title: 'Clôturer la réclamation',
                text: 'Vous êtes sûr de vous ? Vous ne pourrez plus proposer de dédommagement si vous clôturez cette réclamation. ',
                cancel: 'Annuler',
            },
            beginProcessModal: {
                title: 'Avant de commencer…',
                text: 'Vous êtes sur le point de communiquer avec le client et votre message lui sera envoyé par email. N’oubliez pas que vous représentez Burger King !  En cas de questions ou problèmes, contactez votre équipe support.',
                vip: 'C’est un client VIP Kingdom : Prenez en soin !',
                confirm: 'Ok, continuer',
            },
            reportModal: {
                title: 'Signaler une réclamation',
                desc: `Veuillez sélectionner un motif approprié afin de signaler cette réclamation au service consommateur.`,
                highlighText:
                    'En signalant cette demande, vous acceptez que le service consommateur BK procède au traitement de cette demande de manière autonome',
                reasonPlaceholder: `Motif de réclamation`,
                otherReasonPlaceholder: `Précisez le motif ...`,
                message: 'Veuillez insérer un message pour le service de consommation à propos de votre réclamation.',
                successNotif: 'Un email à bien été envoyé au service consommateur',
                reportReasons: {
                    [ComplaintReportReason.RESTAURANT_ERROR]: 'Mauvais restaurant',
                    [ComplaintReportReason.KINGDOM_ERROR]: 'Problème Kingdom',
                    [ComplaintReportReason.DELIVERY_ERROR]: 'Problème Delivery',
                    [ComplaintReportReason.FOOD_POISONING]: 'Intoxication alimentaire',
                    [ComplaintReportReason.OTHER]: 'Autre',
                },
            },
            closeModal: {
                title: 'Clôturer la réclamation',
                desc: `Veuillez sélectionner un motif approprié afin de pouvoir clôturer cette réclamation. Votre message sera directement envoyé par email au client et pourra faire l'objet d'analyse de la part de notre équipe consommateur.`,
                reasonPlaceholder: `Sélectionnez un motif de clôture`,
                otherReasonPlaceholder: `Précisez le motif ...`,
                message: {
                    editablePart: '[INSERER ICI VOTRE PROBLEMATIQUE et LES RAISONS DU REFUS]',
                    partOne: `Bonjour {{clientFullName}}\n\nNous faisons suite à votre réclamation du {{- complaintCreationDate}} à la suite de votre visite dans notre restaurant {{restaurantName}}.`,
                    partTwo: {
                        [ComplaintClosureReason.ABUSIVE]: `Nous avons bien reçu votre demande, malheureusement, nous ne pouvons pas y donner une suite favorable.`,
                        [ComplaintClosureReason.INCOMPREHENSIBLE]: `Nous avons bien reçu votre demande, malheureusement, nous n’arrivons pas à identifier le problème que vous avez rencontré. En l’état, votre réclamation ne nous permet donc pas d’y donner une suite favorable.`,
                        [ComplaintClosureReason.INCOMPLETE]: `Nous avons bien reçu votre demande, malheureusement, celle-ci est incomplète. Votre réclamation ne nous permet donc pas d’y donner une suite favorable et de vous proposer un dédommagement.`,
                        [ComplaintClosureReason.ALREADY_PROCESSED]: `Nous tenons à vous remercier suite à notre échange et nous nous permettons de clôturer votre demande.`,
                        [ComplaintClosureReason.OTHER]: '',
                    },
                    partThree: {
                        [ComplaintClosureReason.ABUSIVE]: `Nous restons disponibles et espérons vous retrouver très vite autour d'un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                        [ComplaintClosureReason.INCOMPREHENSIBLE]: `Nous restons disponibles si vous avez la moindre information supplémentaire à nous fournir. \n\nOn se retrouve très vite autour d'un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                        [ComplaintClosureReason.INCOMPLETE]: `Nous restons disponibles si vous avez la moindre information supplémentaire à nous fournir. \n\nOn se retrouve très vite autour d'un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                        [ComplaintClosureReason.ALREADY_PROCESSED]: `On se retrouve très vite autour d'un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                        [ComplaintClosureReason.OTHER]: `On se retrouve très vite autour d'un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                    },
                },
                successNotif: 'Un email à bien été envoyé au client',
            },
            messageModal: {
                title: 'Votre message',
                restaurantMeeting: {
                    title: 'Proposer une rencontre en restaurant',
                    adviceDate: 'Proposez une date et un horaire au client pour venir en restaurant',
                    adviceManager: 'Soyez sûr qu’un manager soit disponible pour rembourser le client',
                    adviceAddress: 'Les coordonnées du restaurant seront automatiquement ajoutées à l’email',
                },
                selectDate: 'Sélectionnez une date de rencontre',
                label: 'Date',
                initialValue: 'jj/mm/aaaa',
                successNotif: 'Le coupon sélectionné à bien été envoyé',
            },
            refundChoiceModal: {
                title: 'Proposer un dédommagement',
                coupon: 'Offrir un coupon',
                couponDescription: 'Le client obtiendra un coupon à utiliser en restaurant ou application Burger King.',
                crown: 'Remboursement en couronnes',
                crownDescription:
                    'Le client obtiendra une compensation en couronnes à utiliser en restaurant ou application Burger King.',
                restaurant: 'Rencontre en restaurant',
                restaurantDescription:
                    'Proposez au client de venir en restaurant pour lui offrir un remboursement de votre choix.',
            },
            listCouponModal: {
                title: {
                    [RefundType.COUPON]: 'Choisissez le coupon à offrir',
                    [RefundType.CROWN]: 'Choisissez le nombre de couronnes',
                },
                couponDescription: 'Sur sa prochaine commande',
                crownDescription: 'Crédité sur son compte kingdom',
                crown: '{{count}} Couronnes',
            },
            templateMail: {
                template: {
                    editable: '[INSÉRER VOTRE PROBLÉMATIQUE ICI]',
                    firstPart:
                        'Bonjour {{clientFullname}}, \n\nNous faisons suite à votre réclamation du {{date}} concernant votre visite dans notre restaurant {{restaurantName}}. \n\nNous avons bien reçu votre demande et sommes profondément navrés que votre expérience dans notre restaurant n’ait pas été à la hauteur de vos attentes.',
                    [RefundType.COUPON]: `Par conséquent, nous avons le plaisir de vous proposer un dédommagement en coupon à hauteur de {{amount}}.\n\nPour profiter de votre code coupon en restaurant, saisissez-le dans la rubrique "Ajouter un code promo". Pour profiter de votre code coupon sur l'app', vous pouvez l'ajouter directement dans votre parcours de commande via l'encart "Un coupon, une promo" \n\nCette offre est valable dans ce restaurant BURGER KING® pendant 30 jours, non cumulable avec d'autres offres en cours et hors service de livraison via agrégateurs.\n\nNous restons à votre disposition et espérons vous revoir très vite autour d’un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                    [RefundType.CROWN]: `Par conséquent, nous vous proposons un dédommagement en couronnes à hauteur de {{amount}} que vous pourrez utiliser dans le restaurant de votre choix pour vous faire plaisir !\n\nPour rappel, vos couronnes peuvent être changées contre des produits\n\nNous restons à votre disposition et espérons vous revoir très vite autour d’un bon WHOPPER®.\n\nA très bientôt,\n{{restaurantName}}`,
                    [RefundType.RESTAURANT_MEETING]: `Nous vous proposons donc de vous présenter au restaurant le {{meetingDate}} afin de vous dédommager sur place.\nNous restons à votre disposition jusqu'à votre venue en restaurant !\n\nA très bientôt,\n{{restaurantName}}`,
                },
                reformulate: 'REFORMULER',
            },
            displayPhoneNumber:
                "Vous souhaitez échanger avec le client par téléphone ? Suite à votre appel, n'oubliez pas de clôturer ou de dédommager le client.",
            contestTitle: 'Veuillez préparer vos preuves pour contester cette réclamation.',
            contestText:
                'Vous êtes sur le point de contester la réclamation n°{{id}}, ayant pour motif “{{issue}}”.  Préparez l’ensemble de vos documents preuves afin de nous les faire parvenir en pièces jointes au service consommation dans la fenêtre suivante. ',
            contestComplaint: 'Réclamation contestée avec succès',
            description: 'Description',
            descriptionEmpty: 'Pas de description communiquée',
            emptyField: 'Non communiqué',
            deliver: 'Livrée à ',
            complaintNumber: 'N°réclamation',
            inputPlaceholder: 'Ajouter votre message ici pour communiquer avec le client',
            inCharge: {
                [Responsibility.BK]: 'Burger king',
                [Responsibility.CLIENT]: 'Client',
                [Responsibility.DELIVERY]: 'Livraison',
                [Responsibility.RESTAURANT]: 'Restaurant',
            },
            complaintTypes: {
                [ComplaintType.DELIVERY]: 'King Delivery',
                [ComplaintType.RESTAURANT]: 'Restaurant',
                [ComplaintType.CLICK_AND_COLLECT]: 'Click & Collect',
            },
            closureReasons: {
                [ComplaintClosureReason.ABUSIVE]: 'La réclamation est abusive',
                [ComplaintClosureReason.INCOMPREHENSIBLE]: 'La réclamation est incompréhensible',
                [ComplaintClosureReason.INCOMPLETE]: 'La réclamation est incomplète',
                [ComplaintClosureReason.ALREADY_PROCESSED]: 'Réclamation déjà traitée (message/téléphone)',
                [ComplaintClosureReason.OTHER]: 'Autre (message libre)',
            },
            refund: {
                crown: '{{crownAmount}} Couronnes',
                coupon: 'Coupon : {{label}}',
                restaurant: 'Rencontre en restaurant',
                noRefund: 'Pas de remboursement proposé',
            },
            successNotifMessage: 'Un email à bien été envoyé au client',
            editIssue: 'Modifier le motif',
            issue: 'Motif',
            subIssue: 'Sous-Motif',
            issues: {
                myOrder: 'Ma commande',
                myExperience: 'Mon expérience',
                myCurrentOrder: 'Ma commande en cours',
                myPastOrders: 'Mes commandes passées',
            },
            subIssues: {
                appTechnicalIssue: "Problème technique sur l'application",
                dysfuncProducts: 'Produits défectueux',
                loyaltyProgram: 'Programme de fidélité',
                missingProduct: 'Produit manquant',
                missingProducts: 'Produits manquants',
                myRestaurant: 'Mon restaurant',
                orderNotReceived: 'Commande non reçue',
                orderQuality: 'Qualité de la commande',
                other: 'Autre',
                paymentIssue: 'Problème de paiement',
                productsOutOfStock: 'Rupture de produits',
                promoCode: 'Code promotionnel',
                serviceAndDelay: "Service et temps d'attente",
                wrongProduct: 'Mauvais produit reçu',
            },
            kingdomID: 'ID Kingdom',
            orderNumber: 'N° de Commande',
            price: 'Prix',
            proposedRefund: 'Montant du remboursement proposé',
            responsability: 'Responsabilité',
            complaintType: 'Type',
            isReported: 'Réclamation signalée au service consommateur',
            status: {
                title: 'Statut',
                [ComplaintStatus.ALL]: 'Tous',
                [ComplaintStatus.CLOSED]: 'Fermé',
                [`${ComplaintStatus.CLOSED}_other`]: 'Crédité',
                [ComplaintStatus.REFUNDED]: 'Remboursé',
                [ComplaintStatus.TO_REFUND]: 'À rembourser',
                [`${ComplaintStatus.TO_REFUND}_other`]: 'À créditer',
                [ComplaintStatus.REFUND_CONFIRMED]: 'À rembourser',
                [ComplaintStatus.REFUND_VALIDATED]: 'Redirigé service conso',
                [ComplaintStatus.WAITING_SUPPORT]: 'Redirigé service conso',
                [ComplaintStatus.WAITING_CUSTOMER]: 'En attente réponse client',
                [ComplaintStatus.WAITING_RESTAURANT]: 'À vérifier',
                [ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER]: 'À vérifier - Relance 1',
                [ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER]: 'À vérifier - Relance 2',
                [ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER]: 'À vérifier - Dernière relance',
                [ComplaintStatus.SYSTEM_CONTEST_REFUND]: 'Redirigé service conso',
                [ComplaintStatus.SYSTEM_CONFIRM_REFUND]: 'Remboursement en cours',
                [ComplaintStatus.REFUNDED_BY_CROWN]: 'Crédit en cours',
                [ComplaintStatus.REFUNDED_BY_MONEY]: 'Remboursement en cours',
                [ComplaintStatus.NOTIFY_CLOSED]: 'Crédit en cours',
                [ComplaintStatus.NOTIFY_CONTEST_REFUND]: 'Redirigé service conso',
                [ComplaintStatus.NOTIFY_CSI]: 'Remboursement en cours',
                [ComplaintStatus.NOTIFY_NEPTING]: 'Remboursement en cours',
                [ComplaintStatus.NOTIFY_REFUNDED]: 'Remboursement en cours',
                [ComplaintStatus.NOTIFY_VALIDATE_REFUND]: 'Redirigé service conso',
                [ComplaintStatus.NOTIFY_WAITING_SUPPORT]: 'Redirigé service conso',
                [ComplaintStatus.CSI_ERROR]: 'Remboursement en cours',
                [ComplaintStatus.CSI_REFUNDED]: 'Remboursement en cours',
                [ComplaintStatus.NEPTING_ERROR]: 'Remboursement en cours',
                [ComplaintStatus.REFUNDED_MANUALLY]: 'Remboursé',
                [ComplaintStatus.CLOSED_MANUALLY]: 'Fermé',
                [ComplaintStatus.MAX_RETRIED]: 'Remboursement en erreur',
            },
            fraudScoring: {
                [FraudScoring.HIGH]: {
                    title: 'Risque de fraude élevé',
                    subTitle: 'Attention, le client a fait de nombreuses réclamations',
                },
                [FraudScoring.MEDIUM]: {
                    title: 'Risque de fraude moyen',
                    subTitle: 'Soyez prudent, ce client a déjà fait plusieurs réclamations',
                },
                [FraudScoring.LOW]: {
                    title: 'Risque de fraude faible',
                    subTitle: 'Tout va bien, ce client semble fiable',
                },
            },
            take: 'Emportée à ',
            timeLeft: 'Temps restant',
            title: 'Réclamation #{{id}}',
            validate: 'Réclamation validée avec succès',
            validateRefund: 'Remboursement validé avec succès',
            banner: {
                description: 'Dernière chance pour traiter vos réclamations en attente !',
                button: 'Voir les réclamations',
            },
            modal: {
                title: 'Réclamation en attente de traitement !',
                maxDelay: 'Chaque réclamation doit être traité dans un délai de 72h maximum',
                automaticRefund: 'Les réclamations non traitées sont automatiquement dédommagées',
                button: 'Voir les réclamations',
            },
        },
        orders: {
            article_one: '{{count}} article',
            article_other: '{{count}} articles',
            arrivalTime: 'Heure d’arrivée chez le client ',
            deliveryFees: 'Coût de livraison',
            deliveryStatus: {
                [DeliveryStatus.PENDING]: 'Recherche livreur',
                [DeliveryStatus.PICKING]: 'Livreur trouvé',
                [DeliveryStatus.ALMOST_PICKING]: 'Livreur en approche restaurant',
                [DeliveryStatus.WAITING_AT_PICKUP]: 'Livreur en attente restaurant',
                [DeliveryStatus.DELIVERING]: 'En cours de livraison',
                [DeliveryStatus.ALMOST_DELIVERING]: 'Livreur en approche client',
                [DeliveryStatus.WAITING_AT_DROPOFF]: 'Livreur en attente client',
                [DeliveryStatus.DELIVERED]: 'Livrée',
                [DeliveryStatus.CANCELLED]: 'Livraison annulée',
                [DeliveryStatus.SCHEDULED]: 'Livraison programmée',
            },
            deliveryPartner: 'Livraison',
            errorOnly: 'Afficher uniquement les erreurs',
            empty: 'Rien pour le moment\u00A0!',
            extra: 'Extra',
            finalTotal: 'TOTAL',
            free: 'Offert',
            ice: 'Avec glaçons',
            integrateOrder: 'Commande réintégrée avec succès',
            noContent: 'Pas de produit',
            noIce: 'Sans glaçons',
            noData: "Nos systèmes n'enregistrent aucunes commandes en cours pour le moment. Veuillez repasser plus tard ou rafraîchissez la page.",
            number: 'N°Commande',
            pickUpTime: 'Heure d’arrivée en restaurant ',
            pickUpType: {
                title: 'Mode de retrait',
                [PickUpType.DELIVERY_IN_APP]: 'King Delivery',
                [PickUpType.DRIVE]: 'Drive',
                [ServiceCode.CLICK_AND_COLLECT]: 'Click & Collect',
                [PickUpType.ON_SITE]: 'Click & Stay',
                [PickUpType.PARKING]: 'Parking',
                [PickUpType.PICK_UP]: 'Click & Go',
                [PickUpType.TABLE_SERVICE]: 'Click & Stay',
                [PickUpType.TABLE_ORDER]: 'King Table',
                [PickUpType.ALL]: 'Tous',
            },
            price: 'Prix',
            product: '{{number}} {{operator}} {{label}} {{price}}',
            status: {
                [OrbStatus.ACCEPTED]: 'Acceptée',
                [OrbStatus.WAITING_FOR_PAYMENT]: 'En attente de payment',
                [OrbStatus.PREPARING]: 'En préparation',
                [OrbStatus.READY_FOR_COLLECT]: 'En attente de récupération',
                [OrbStatus.DELIVERED]: 'Délivrée',
                [OrbStatus.DISMISSED]: 'Annulée',
                [OrbStatus.WAITING_FOR_PRODUCTION]: 'En attente de production',
                [OrbStatus.WAITING_FOR_RESOLUTION]: 'En attente de résolution',
                [OrbStatus.INTEGRATION_ERROR]: 'En attente de rattrapage',
                [OrbStatus.MANUAL_INTEGRATION]: 'Rattrapée manuellement',
            },
            titleDetail: 'Commande #{{orderNumber}} - {{clientNameLabeled}}',
            total: 'Sous-total',
            TVA: 'TVA (20%)',
            vip: 'VIP',
            vipId: 'VIP Kingdom',
            without: 'Sans',
        },
        tutorial: {
            title: 'Comment ça marche ?',
            button: 'Voir plus',
            tabs: {
                manageComplaint: 'Gestion des réclamations',
                restaurantAndCc: 'Restaurant et Click & Collect',
                delivery: 'King Delivery',
            },
            [TutorialTab.MANAGE_COMPLAINT]: {
                help: 'Besoin d’aide ? Contactez le service consommateur, ouvert du lundi au vendredi de 11H30 à 20H au 08 00 97 80 80 ou par email à l’adresse bonjour@burgerking.fr.',
                [AccordionDetailsKey.GOAL]: {
                    title: 'Objectif',
                    description:
                        'BOB permet de centraliser les différentes typologies de réclamations et ainsi faciliter la gestion aux franchisés et aux restaurants.',
                },
                [AccordionDetailsKey.HOW_IT_WORKS]: {
                    title: 'Comment ça marche ?',
                    description: `Une réclamation découle des canaux de commande suivants :<br/> • Restaurant<br/> • Click & Collect<br/> • King Delivery<br/> <br/> Chaque mode de commande ayant ses spécificités, les réclamations associées ne sont pas traitées de la même manière.`,
                },
            },
            [TutorialTab.RESTAURANT_AND_CC]: {
                description:
                    "Les réclamations Restaurant et Click&Collect sont deux typologies de réclamations à traiter par les restaurants franchisés. <br/>Elles engagent votre responsabilité et le service consommateur n'en est pas responsable, il ne peut pas dédommager les clients.<br/><br/>• Une réclamation Restaurant : concerne toute commande effectuée en borne restaurant, en caisse ou toute expérience vécue au sein d'un de vos restaurants.<br/>• Une réclamation Click&Collect : concerne toute commande Click&Collect passée via le site ou l'application Burger King que le client récupère en restaurant.",
                [AccordionDetailsKey.DECRYPT_COMPLAINT]: {
                    title: 'Les choix possibles',
                    description:
                        'Vous avez les possibilités suivantes : <br/>• Dédommager directement le client via un des modes de dédommagement proposé<br/>• Clôturer la réclamation dans le cas où vous ne souhaitez pas donner suite<br/>• Appeler le client via le numéro de téléphone affiché dans les informations client<br/>• Répondre au client par mail via le champ prévu à cet effet<br />',
                },
                [AccordionDetailsKey.REFUND_CUSTOMER]: {
                    title: 'Dédommager un client',
                    description:
                        "Lorsque le restaurant souhaite dédommager un client, il faut choisir un dédommagement parmi la liste disponible, en l'accompagnant par un message qui sera envoyé au client. Dans le cas d'un client VIP, une mention le précise avant la procédure : « C’est un client VIP Kingdom: Prenez en soin ! ».<br/><br/>Il existe trois options de dédommagement proposées : « Offrir un coupon », « Rembourser en couronnes » ou « Rembourser en restaurant ».<br/><br/>",
                    descriptionMax:
                        'À la sélection du dédommagement <strong>« Offrir un coupon » :</strong> <br/>Un code coupon unique est envoyé au client par mail (un pourcentage sur le montant total de l’addition ou un produit offert).<br/><br/>À la sélection du dédommagement <strong>« Remboursement en couronnes » :</strong><br/>Vous choisissez d’attribuer entre 40 à 120 couronnes. <br/>• Ces couronnes expirent au bout de 30 jours.<br/>• Ce type de remboursement est valable uniquement pour les clients Kingdom.<br/><br/>À la sélection du dédommagement <strong>« Rencontre en restaurant »</strong>: Le restaurant envoie un message au client en lui proposant de se présenter au restaurant à une certaine date.<br/><br/>Ces trois solutions de dédommagement entrainent un changement de statut de la réclamation : statut « Remboursée » puis « Clôturée ».',
                },
                [AccordionDetailsKey.CLOSE_COMPLAINT]: {
                    title: 'Répondre défavorablement au client',
                    description:
                        "Lorsque le restaurant ne souhaite pas dédommager un client, il suffit de « Clôturer la réclamation ». <br/>Une confirmation est demandée puisque cette action est irréversible.<br/><br/>Le restaurant doit choisir parmi une liste de motifs justifiant cette réponse défavorable. La clôture s’accompagnera d’un message pré-rempli qu'il est possible de compléter/éditer. À la validation (bouton « Valider »), un mail est envoyé au client et la réclamation est considérée comme clôturée.",
                },
                [AccordionDetailsKey.REPORT_COMPLAINT]: {
                    title: 'Signaler une réclamation au service consommateur',
                    description:
                        'Il sera maintenant possible depuis la tablette BOB de renvoyer une réclamation au service consommateur Armatis pour traitement, via la fonctionnalité <strong>signaler une réclamation au service consommateur</strong>. Ce bouton est disponible depuis la vue de détails de la réclamation. En cliquant sur « Signaler », le restaurant doit sélectionner un motif approprié afin d’expliquer la raison de son signalement. Le restaurant devra également rédiger un message texte explicatif à l’attention du service consommateur.',
                },
                [AccordionDetailsKey.EXCHANGE_CUSTOMER]: {
                    title: 'Échanger avec un client avant dédommagement',
                    description:
                        'Avant de dédommager ou clôturer la réclamation, le restaurant a la possibilité d’échanger avec un client via la fonctionnalité d’envois de mails.<br/><br/>Une fois le message envoyé, la réclamation passe au statut « En attente réponse client ». À chaque nouvelle réponse du client, la réclamation repasse au statut « À vérifier » entrainant une notification. L’historique des échanges est consultable dans le détail de la réclamation.',
                },
                [AccordionDetailsKey.NOTIFICATIONS]: {
                    title: 'Notifications et relances',
                    description:
                        'Un système de notifications ainsi que de relances sont également mis en place afin d’assurer la prise en charge des réclamations par les restaurants dans les temps souhaités et une meilleure satisfaction client.<br/><br/>Il existe trois relances lorsque le restaurant ne traite pas la réclamation : <br/>• 24H : première relance<br/>• 48H : deuxième relance<br/>• 60H : troisième relance<br/>Au bout de 72H, la réclamation est basculée au service consommateur. Le service digital prendra ensuite la main sur le dédommagement qui sera maximum en fonction de la problématique du client.',
                },
            },
            [TutorialTab.KING_DELIVERY]: {
                description:
                    "Les réclamations King Delivery concernent les commandes en livraison effectuées via l'application Burger King.<br/><br/> Attention, les réclamations King Delivery ne peuvent être ouvertes que par le service consommateur. Pour des raisons fiscales, il est fortement déconseillé de dédommager un client qui se présenterait directement en restaurant si la réclamation n'a pas été ouverte au préalable par le service consommateur.",
                [AccordionDetailsKey.VALIDATE_REFUND]: {
                    title: 'Valider un remboursement',
                    description:
                        "Dans le cas d'une réclamation en responsabilité <strong>Restaurant</strong>, vous avez la possibilité de valider cette réclamation via un bouton « Valider ».<br/> Cela déclenchera un remboursement automatique chez le client. ",
                },
                [AccordionDetailsKey.CONTEST_REFUND]: {
                    title: 'Contester une réclamation',
                    description:
                        "Dans le cas d'une réclamation en responsabilité <strong>Restaurant</strong>, vous avez la possibilité de contester cette réclamation via un bouton « Contester ».<br/>Vous pourrez ajouter des pièces jointes pour justifier la contestation via un chatbot. Après contestation d’une réclamation, le service consommateur l'étudie et prend une décision sous 72h.",
                },
                [AccordionDetailsKey.DELIVERY_CLAIM]: {
                    title: 'Réclamation en responsabilité livraison',
                    description:
                        'Pour ce type de réclamation, vous avez tout de même la possibilité, par une action manuelle, de valider le remboursement en cas de responsabilité Livraison via le bouton « Valider ». Ceci accélèrera le remboursement auprès du client. Sans action de votre part, le remboursement est automatiquement validé sous 24H.',
                },
            },
        },
        services: {
            birthday: {
                management: 'Gestion des anniversaires',
                manageReservations: 'Gérer mes réservations',
                upcoming: 'Anniversaires à venir',
                time: 'Horaires de réservation du service',
                noData: "Aucune réservation n'a été trouvée. Veuillez repasser plus tard ou rafraîchissez la page",
                timeInfo:
                    'L’horaire de début correspond au premier horaire réservable par le client. L’horaire de fin correspond au dernier horaire réservable par le client',
                info: 'Pour désactiver le service anniversaire, veuillez contacter votre conseiller franchisé.',
                status: {
                    confirmed: 'Confirmé',
                    inProgress: 'En attente',
                    denied: 'Refusé',
                },
                gridItems: {
                    status: 'Statut',
                    number: 'Numéro',
                    date: 'Date',
                    hour: 'Heure',
                    parentPhone: 'Téléphone du parent',
                    gift: 'Cadeau',
                },
                reservation: {
                    statusModifier: 'Modifier le statut :',
                    status: 'Statut :',
                    numberOfChildren: "Nombre d'enfants",
                    gift: 'Cadeau',
                    cake: 'Gâteau',
                    comments: 'Remarques particulières (allergies, demandes spécifiques...)',
                    parent: 'Parent',
                    VIPKingdom: 'VIP Kingdom',
                    child: 'Enfant',
                    bookingNumber: 'Réservation N°{{bookingNumber}}',
                },
                dropdown: {
                    confirmation: 'Confirmer la réservation',
                    cancellation: 'Annuler la réservation',
                    modification: 'Modifier la réservation',
                },
                confirmModal: {
                    reservationModified: 'Reservation modifiée',
                    reservationValidated: 'Reservation validée',
                    reservationCancelled: 'La réservation a bien été annulée',
                    modificationMail:
                        'Un email récapitulatif avec la date, horaire et nombre d’enfants pour cette réservation anniversaire à été envoyé au client.',
                    validationMail:
                        'Un email récapitulatif avec la date et horaire de cette reservation anniversaire à été envoyé au client.',
                    cancellationMail:
                        'Un email récapitulatif d’annulation de réservation anniversaire à été envoyé au client.',
                },
                defineLongComment: {
                    title: 'Remarques Particulières',
                    description:
                        'Ces consignes sont à prendre en compte vous ont été partagées par le client, vous pouvez les personnaliser dans le cadre de vos échanges',
                },
                calendarSelection: {
                    text: "Choisissez une nouvelle date, un horaire, et le nombre d'enfants attendu pour cette réservation anniversaire.",
                    hourLabel: 'Horaires',
                    numberOfChildrenLabel: "Nombre d'enfants",
                    notAvailable: `Le service n'est pas disponible le jour séléctionné`,
                    select: 'Veuillez sélectionner une horaire parmi celles disponibles',
                },
                forceCall: {
                    inProgressTitle: "N'oubliez pas d'appeler le client !",
                    inProgressText: 'Appeler le client avant de modifier',
                    cancellationTitle: "Êtes vous sûrs d'annuler cette demande ?",
                    cancellationText: "Appelez le client avant d'annuler",
                    confirmCancellation: 'Oui, annuler la demande',
                },
                tabs: {
                    current: 'À venir',
                    end: 'Passés',
                },
            },
            card: 'Gestion et préférences ',
            delivery: {
                activate: 'Activer ou désactiver le service King Delivery temporairement (pendant 24h)',
                name: 'Service King Delivery',
                pickupInfo: 'Consignes de pickup en restaurant',
                pickupInfoDefault: 'Aucune indication pour le livreur',
                modalPickupInfoTitle: 'Consignes de pickup en restaurant',
                modalPickupInfoSubTitle:
                    'Ces consignes seront partagées avec les livreurs récupérant une commande au sein de votre restaurant',
                deliveryPartner: 'Partenaire de Livraison : ',
            },
            kingTable: {
                name: 'Service King Table',
                toggle: 'Désactiver le service King Table temporairement (pendant 24h)',
                qrCode: 'QR codes King Table',
                qrCodeDescription: 'Générer un bon de commande pour vos QR Codes tables.',
                button: 'Générer un bon de commande',
                management: {
                    title: 'Demande de QR Codes King Table',
                    restaurant: {
                        title: 'Restaurant',
                        description: 'Indiquez le restaurant bénéficiaire',
                        placeholder: 'Choississez votre restaurant',
                    },
                    terrace: {
                        title: 'Terrasse',
                        description: "Le restaurant dispose-t-il d'une terrasse ?",
                        labelTerrace: 'Oui, une terrasse extérieure',
                        labelNoTerrace: 'Non, pas de terrasse',
                    },
                    table: {
                        title: 'Disposition des tables',
                        description: 'Indiquez les tables que vous possédez ',
                        lowerBoundTableNumber: 'Numéro de la première table',
                        upperBoundTableNumber: 'Numéro de la dernière table',
                        terrace: 'Terrasse extérieur',
                        room: 'Salle intérieur',
                        error: 'Le numéro de la première table ne peut pas être supérieur au numéro de la dernière table. ',
                        overlapingTerraceError: 'Certains numéros saisis ont déjà été utilisés. ',
                        overlapingRoomError:
                            'Des numéros de cette série sont déjà utilisés dans la terrasse extérieure. ',
                        minValueError: 'Un numéro de table doit être supérieur à zéro.',
                        requiredError: 'Ce champ ne doit pas être vide.',
                    },
                    button: 'Confirmer',
                    confirmationModal: {
                        title: 'Confirmer mon bon de commande',
                        adviceOne: 'Assurez-vous d’avoir bien ajouté l’ensemble de vos tables ',
                        adviceTwo:
                            'Vérifiez que le nombre de tables inscrit correspond bien à la disposition de votre salle',
                        roomTable: 'Tables en intérieur :',
                        terraceTable: 'Tables en extérieur :',
                        roomTableNumber_one: '{{count}} Table (N°{{min}} à {{max}} inclus)',
                        roomTableNumber_other: '{{count}} Tables (N°{{min}} à {{max}} inclus)',
                        terraceTableNumber_one: '{{count}} Table (N°{{min}} à {{max}} inclus)',
                        terraceTableNumber_other: '{{count}} Tables (N°{{min}} à {{max}} inclus)',
                        qrCode: 'Total QR codes :',
                        descriptionQrCode: '{{count}} QR Codes King Table',
                        buttons: {
                            cancel: 'Annuler',
                            confirm: 'Confirmer',
                        },
                    },
                    downloadModal: {
                        title: 'Fichiers envoyés !',
                        description:
                            "Votre bon de commande et votre fichier d'impression des QR codes ont bien été envoyés à votre adresse : {{email}}. Vous pouvez également le télécharger en cliquant sur le bouton ci-dessous. Pensez à vérifier votre bon de commande : vous pourrez toujours en générer un nouveau si besoin !",
                        buttons: {
                            confirm: 'Télécharger',
                        },
                    },
                },
            },
            driveParking: {
                change: 'Sélectionner le service Click & Parking ou Click & Drive',
                modalSwapTitle: 'Basculer en mode Drive',
                modalSwapSubTitle: 'Définir un horaire de bascule pour le passage du service parking au service drive',
                name: 'Click & Parking / Click & Drive',
                swapActive: 'Basculer automatiquement en mode Click & Drive à la fermeture du Click & Parking',
                swapTime: 'Fermeture du service Click & Parking: {{time}}',
                [ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING]: 'Parking',
                [ServiceCode.CLICK_AND_COLLECT_DRIVE]: 'Drive',
                [ServiceCode.NO_DRIVE_PARKING]: 'Aucun',
            },
            servicesCode: {
                [ServiceCode.APPLICATION]: 'Application',
                [ServiceCode.CLICK_AND_COLLECT]: 'Click & Collect',
                [ServiceCode.RESTAURANT]: 'Restaurant',
                [ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING]: 'Click & Parking / Click & Drive',
                [ServiceCode.CLICK_AND_COLLECT_DRIVE]: 'Click & Parking / Click & Drive',
                [ServiceCode.NO_DRIVE_PARKING]: 'Click & Parking / Click & Drive',
                [ServiceCode.DELIVERY_IN_APP]: 'King Delivery',
                [ServiceCode.TABLE_ORDER]: 'King Table',
                [ServiceCode.BIRTHDAY]: 'Anniversaire',
            },
            subtitleId: 'ID Magasin\u00A0: {{id}}',
        },
        statistics: {
            title: 'Détails réclamations',
        },
        status: {
            orders: {
                [StatusType.ERROR]: 'Erreur',
                [StatusType.IN_PROGRESS]: 'En cours',
                [StatusType.FINISHED]: 'Terminé',
                [StatusType.ALL]: 'Reçues',
            },
            realTimeActive: 'Temps réel actif',
            realTimeInactive: 'Temps réel inactif',
            title: 'Statut',
        },
        time: {
            date: 'Date',
            hour: 'Heure',
            left_one: 'restante',
            left_other: 'restantes',
            minute_one: 'minute',
            minute_other: 'minutes',
            noTime: 'X',
            start: 'Date de début',
            end: 'Date de fin',
        },
        error: {
            birthday: {
                [ErrorBirthday.SERVICE_INVALID_NUMBER_DAYS]: 'Le nombre de jours du formulaire est incorrect',
                [ErrorBirthday.SERVICE_OPENING_HOURS_NOT_FILLED]:
                    "Les horaires d'ouverture et de fermeture du service du {{day}} ne sont pas communiquées.",
                [ErrorBirthday.SERVICE_OPENING_HOUR_STARTS_BEFORE]:
                    "L'heure d'ouverture du service du {{day}} commence avant l'heure d'ouverture du restaurant.",
                [ErrorBirthday.SERVICE_ENDING_HOUR_ENDS_AFTER]:
                    "L'heure de fermeture du service du {{day}} se termine après l'heure de fermeture du restaurant.",
                [ErrorBirthday.SERVICE_BIRTHDAY_DURATION]:
                    "Le service ANNIVERSAIRE est disponible moins de 2 heures le {{day}}. Le créneau d'ouverture doit être d'au moins 2 heures.",
            },
            birthdayActivationDay: 'Vous ne pouvez pas activer un jour avec des horaires non définis',
            birthdayService:
                'Une erreur inattendue est survenue. Impossible de changer les horaires anniversaires pour le moment',
            birthdayStatus:
                "Une erreur inattendue est survenue. Impossible d'actualiser l'anniversaire. Veuillez réessayer ultérieurement.",
            code: "Code d'erreur\u00A0: {{value}}",
            contestComplaint:
                'Une erreur inattendue est survenue. Impossible de contester la réclamation pour le moment',
            patchComplaint: 'Une erreur inattendue est survenue lors de la modification de la réclamation',
            refundCustomer: 'Une erreur inattendue est survenue. Impossible de rembourser le client pour le moment',
            sendMessage: "Une erreur inattendue est survenue. Impossible d'envoyer votre message",
            credentials: 'Votre identifiant ou votre mot de passe est incorrect',
            default: "Désolé, une erreur inattendue est survenue. Veuillez réessayer ultérieurement s'il vous plaît.",
            integrateOrder: 'Une erreur inattendue est survenue. Impossible de réintégrer la commande pour le moment',
            mail: 'Email invalide',
            newOrderError: 'Veuillez intégrer manuellement la commande.',
            newOrderErrorNumber: 'Erreur Commande #{{orderNumber}}',
            noPermissionTitle: "Oups, vous n'avez pas les droits...",
            NoPermissionDescription:
                'Nous sommes incapables de vous afficher cette page pour le moment. Veuillez vérifier auprès de votre administrateur que votre compte possède les droits nécessaires pour accéder à cette page.',
            noTime: 'Horaire indisponible',
            refundComplaint:
                "Une erreur inattendue est survenue. Impossible d'accepter le remboursement pour le moment",
            required: 'Ce champs est obligatoire',
            serviceActivate: 'Une erreur inattendue est survenue. Impossible de modifier le service pour le moment',
            textMaxLength: 'Le texte ne doit pas dépasser {{maxLength}} caractères',
            unexpected: 'Oups, un problème est survenu...',
            userNotFound: "Le compte associé à cette email n'existe pas",
            validateComplaint:
                'Une erreur inattendue est survenue. Impossible de valider la réclamation pour le moment',
            closeComplaint: 'Une erreur inattendue est survenue. Impossible de clôture la réclamation',
            reportComplaint: 'Une erreur inattendue est survenue. Impossible de signaler la réclamation',
            passwordsMatching: 'Les mots de passe ne sont pas identiques',
            password:
                'Votre mot de passe doit comporter 8 caractères au minimum, au moins 1 lettre majuscule, 1 caractère spécial (@$!%*?&) et un caractère numérique',
            pickupInfoChange:
                'Une erreur inattendue est survenue. Impossible de changer les détails de récupération de commande pour le moment',
            swapDriveParkingActivation:
                "Une erreur inattendue est survenue. Impossible de changer l'activation automatique pour le moment",
            swapDriveParkingTime:
                "Une erreur inattendue est survenue. Impossible de changer l'horaire de fermeture du parking",
            qrCodeSendEmail:
                "Une erreur inattendue est survenue lors de l’envoi de fichier par email. Veuillez réessayer ultérieurement s'il vous plaît.",
            qrCodeDownloadFiles:
                "Une erreur inattendue est survenue lors du téléchargement des fichiers. Veuillez réessayer ultérieurement s'il vous plaît.",
        },
        login: {
            login: 'Login',
            password: 'Mot de passe',
            title: 'Restaurant Manager',
            text: 'Connectez-vous avec vos identifiants restaurant pour accéder à votre interface de gestion',
        },
        setPassword: {
            setTitle: 'Création de mot de passe',
            changeTitle: 'Réinitialisation de mot de passe',
            setTitleValidated: 'Mot de passe créé',
            changeTitleValidated: 'Mot de passe réinitialisé',
            newPassword: 'Nouveau mot de passe',
            passwordConfirm: 'Confirmer le nouveau mot de passe',
            text: 'Entrer un nouveau mot de passe pour le compte associé à l’adresse email {{email}}',
            textValidated:
                'Votre mot de passe à bien été créé, veuillez revenir à l’accueil pour vous connecter avec votre nouveau mot de passe.',
        },
        forgottenPassword: {
            button: 'Mot de passe oublié ?',
            text: 'Veuillez entrer votre email pour recevoir un mail de réinitialisation de mot de passe',
            textValidated:
                "Un mail a été envoyé à l'adresse suivante {{email}}. Veuillez revenir à l’accueil pour vous connecter après avoir changer votre mot de passe.",
            title: 'Mot de passe oublié',
            titleValidated: 'Email envoyé',
            email: 'Email',
        },
    },
}
