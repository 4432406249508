import { PointsIcon } from 'assets/icons'
import { Button } from 'components/button/Button'
import { CloseComplaintProcess } from 'components/close-complaint-process/CloseComplaintProcess'
import { ContestComplaintModal } from 'components/contest-complaint-modal/ContestComplaintModal'
import { DropdownButton } from 'components/dropdown-button/DropdownButton'
import { Notif } from 'components/notif/Notif'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { useOpenModal } from 'hooks/useOpenModal'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { setCurrentComplaint, updateComplaint } from 'redux/complaint/complaintSlice'
import {
    contestComplaint,
    setComplaintChatId,
    validateComplaint,
    validateRefundComplaint,
} from 'redux/complaint/thunks'
import { selectCurrentRestaurant } from 'redux/restaurant/selectors'
import { colors } from 'styles/colors'
import { ComplaintDTO } from 'types/api'
import { ComplaintStatus, ComplaintType, Permissions, RefundType } from 'types/api.enum'
import { FEATURE_TOGGLE_CODE, NotyfType } from 'utils/enums'

import { useGetListStorage } from '../../../redux/storage/storage'
import { chatCustomData } from '../ComplaintDetail'
import { RestaurantComplaintAnswerProcess } from './complaint-answer-process'
import { ReportComplaintProcess } from './ReportComplaintProcess'

type ButtonsRenderProps = {
    currentComplaint: ComplaintDTO
    disabled: boolean
    setPending: Dispatch<SetStateAction<boolean>>
}

export const ButtonsRender = ({ currentComplaint, disabled, setPending }: ButtonsRenderProps) => {
    const { complaintType, status, refundType, complaintNumber, issue, description } = currentComplaint
    const { t } = useTranslation()
    const restaurant = useAppSelector(selectCurrentRestaurant)
    const { complaintId = '' } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [isCloseComplaintModalOpen, setIsCloseComplaintModalOpen] = useState(false)

    const { isOpen: isReportComplaintModalOpen, toggleModal: toggleReportComplaintModal } = useOpenModal()
    const { isOpen: isContestModalOpen, toggleModal: toggleContestComplaintModal } = useOpenModal()
    const { isOpen: isAnswerProcessOpen, toggleModal: toggleAnswerComplaintProcess } = useOpenModal()

    const toggleCloseComplaintModal = () => {
        setIsCloseComplaintModalOpen((x) => !x)
    }

    const openCloseComplaintModal = useCallback(() => {
        setIsCloseComplaintModalOpen(true)
    }, [])

    const refundClickHandler = async () => {
        setPending(true)
        try {
            await dispatch(validateComplaint(complaintId))
            Notif({ type: NotyfType.SUCCESS, text: t('complaints.validate') })
            navigate(t('page.complaints.route'))
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.validateComplaint') })
        } finally {
            setPending(false)
        }
    }

    const validateClickHandler = async () => {
        setPending(true)
        try {
            await dispatch(validateRefundComplaint(complaintId))
            Notif({ type: NotyfType.SUCCESS, text: t('complaints.validate') })
            navigate(t('page.complaints.route'))
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.refundComplaint') })
        } finally {
            setPending(false)
        }
    }

    const contestComplaintValidateHandler = async () => {
        setPending(true)
        try {
            await dispatch(contestComplaint({ complaintId: complaintId, description }))
            await dispatch(
                setCurrentComplaint({
                    ...currentComplaint,
                    status: ComplaintStatus.WAITING_SUPPORT,
                }),
            )
            await dispatch(
                updateComplaint({
                    complaintNumber: currentComplaint.complaintNumber || 0,
                    createdDate: currentComplaint.createdDate || '',
                    endDate: currentComplaint.endDate,
                    id: currentComplaint.id,
                    inCharge: currentComplaint.inCharge,
                    price: currentComplaint.price || 0,
                    refundType: currentComplaint.refundType,
                    status: currentComplaint.status || ComplaintStatus.WAITING_SUPPORT,
                    messages: [],
                }),
            )
            if (window.botmindWidget) {
                try {
                    const id = await window.botmindWidget.loadNewSession({
                        startOpen: true,
                        customData: chatCustomData(currentComplaint, restaurant),
                    })
                    await dispatch(setComplaintChatId({ complaintId: complaintId, chatId: id }))
                    window.botmindWidget.show()
                } catch (error) {
                    console.error('Error loading new botmind session:', error)
                }
            }
        } catch (error) {
            Notif({ type: NotyfType.ERROR, text: t('error.contestComplaint') })
            console.error('Error contesting complaint:', error)
        } finally {
            setPending(false)
        }
    }

    const canRefund =
        complaintType === ComplaintType.DELIVERY &&
        status === ComplaintStatus.TO_REFUND &&
        refundType !== RefundType.CROWN

    const canValidateOrContest =
        complaintType === ComplaintType.DELIVERY && status === ComplaintStatus.WAITING_RESTAURANT

    const franchiseeComplaintTypes: string[] = [ComplaintType.CLICK_AND_COLLECT, ComplaintType.RESTAURANT]

    const waitingRestaurantStatus = [
        ComplaintStatus.WAITING_RESTAURANT,
        ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
        ComplaintStatus.WAITING_CUSTOMER,
    ]

    const isFranchiseeComplaintType = complaintType && franchiseeComplaintTypes.includes(complaintType)
    const canAnswerOrCloseComplaint = !!(
        isFranchiseeComplaintType &&
        status &&
        waitingRestaurantStatus.includes(status)
    )

    const enabledFranchisedReclamation = useFeatureToggle([FEATURE_TOGGLE_CODE.FRANCHISED_RECLAMATION])

    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const { data: allServiceRestaurants } = useGetListStorage()
    const currentRestaurant = allServiceRestaurants?.restaurants[restaurantFrNumber]

    return (
        <>
            <ShouldDisplay permission={Permissions.MANAGE_REFUND} condition={canRefund}>
                <Button
                    color={colors.red}
                    text={t('component.button.refund')}
                    disabled={disabled}
                    onClick={refundClickHandler}
                />
            </ShouldDisplay>
            <ShouldDisplay permission={Permissions.MANAGE_COMPLAINT} condition={canValidateOrContest}>
                <Button
                    color={colors.red}
                    text={t('component.button.validate')}
                    disabled={disabled}
                    onClick={validateClickHandler}
                />
                <Button
                    color={colors.brown}
                    text={t('component.button.contest')}
                    disabled={disabled}
                    onClick={toggleContestComplaintModal}
                />
            </ShouldDisplay>
            <ShouldDisplay
                permission={Permissions.MANAGE_COMPLAINT}
                condition={enabledFranchisedReclamation && canAnswerOrCloseComplaint}
            >
                <Button
                    color={colors.red}
                    text={t('component.button.answer')}
                    disabled={disabled}
                    onClick={toggleAnswerComplaintProcess}
                />
            </ShouldDisplay>
            <ShouldDisplay
                permission={Permissions.MANAGE_COMPLAINT}
                condition={enabledFranchisedReclamation && canAnswerOrCloseComplaint}
            >
                <Button
                    color={colors.brown}
                    text={t('component.button.closeComplaint')}
                    disabled={disabled}
                    onClick={openCloseComplaintModal}
                />
            </ShouldDisplay>
            <ShouldDisplay
                permission={Permissions.MANAGE_COMPLAINT}
                condition={enabledFranchisedReclamation && canAnswerOrCloseComplaint}
            >
                <DropdownButton
                    icon={<PointsIcon />}
                    list={[
                        {
                            text: t('component.dropdown.reportComplaint'),
                            onClick: toggleReportComplaintModal,
                        },
                    ]}
                />
            </ShouldDisplay>
            {isContestModalOpen && (
                <ContestComplaintModal
                    handleClose={toggleContestComplaintModal}
                    complaintNumber={complaintNumber}
                    issue={issue}
                    onValidate={contestComplaintValidateHandler}
                />
            )}
            {isCloseComplaintModalOpen && (
                <CloseComplaintProcess
                    onClose={toggleCloseComplaintModal}
                    complaint={currentComplaint}
                    currentRestaurant={currentRestaurant}
                />
            )}
            {isAnswerProcessOpen && (
                <RestaurantComplaintAnswerProcess
                    handleClose={toggleAnswerComplaintProcess}
                    complaint={currentComplaint}
                    currentRestaurant={currentRestaurant}
                />
            )}

            {isReportComplaintModalOpen && <ReportComplaintProcess handleClose={toggleReportComplaintModal} />}
        </>
    )
}
