import { Cross, LogoBKIcon } from 'assets/icons'
import { Banner, BannerPositionVariant } from 'components/banner/Banner'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H3, H4 } from 'styles/typography'

interface PowerBIBannerProps {
    onClick: () => void
}

export const PowerBIBanner = ({ onClick }: PowerBIBannerProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClose = () => {
        onClick()
    }

    const handleClick = () => {
        navigate(t('page.statistics.route'))
        onClick()
    }

    return (
        <Banner position={BannerPositionVariant.STICKY} hasLinearBackground>
            <StyledBannerContainer>
                <StyledBannerContentContainer>
                    <LogoBKIcon />
                    <H3 color={colors.beige}>{t('page.home.dashboard.banner.title')}</H3>
                    <StyledVerticalLine />
                    <StyledTextContainer>
                        <H4 font={fonts.flame} onClick={handleClick} color={colors.beige}>
                            <Trans
                                i18nKey="page.home.dashboard.banner.description"
                                components={[<StyledUnderline key="underline" />]}
                            />
                        </H4>
                    </StyledTextContainer>
                </StyledBannerContentContainer>
                <Cross size={15} color={colors.beige} onClick={handleClose} />
            </StyledBannerContainer>
        </Banner>
    )
}

const StyledBannerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const StyledBannerContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.M};
    align-items: center;
`

const StyledTextContainer = styled.div`
    display: flex;
    gap: ${SPACING.XXXS};
`

const StyledVerticalLine = styled.div`
    background-color: ${colors.beige};
    width: ${BORDER.WIDTH.XS};
    height: 30px;
`

const StyledUnderline = styled.u`
    cursor: pointer;
`
