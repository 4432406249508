export enum DeliveryStatus {
    PENDING = 'PENDING',
    PICKING = 'PICKING',
    ALMOST_PICKING = 'ALMOST_PICKING',
    WAITING_AT_PICKUP = 'WAITING_AT_PICKUP',
    DELIVERING = 'DELIVERING',
    ALMOST_DELIVERING = 'ALMOST_DELIVERING',
    WAITING_AT_DROPOFF = 'WAITING_AT_DROPOFF',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    SCHEDULED = 'SCHEDULED',
}

export enum OrbStatus {
    ACCEPTED = 'ACCEPTED',
    DELIVERED = 'DELIVERED',
    DISMISSED = 'DISMISSED',
    INTEGRATION_ERROR = 'INTEGRATION_ERROR',
    MANUAL_INTEGRATION = 'MANUAL_INTEGRATION',
    PREPARING = 'PREPARING',
    READY_FOR_COLLECT = 'READY_FOR_COLLECT',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    WAITING_FOR_PRODUCTION = 'WAITING_FOR_PRODUCTION',
    WAITING_FOR_RESOLUTION = 'WAITING_FOR_RESOLUTION',
}

export enum OrderOrigin {
    ACRELEC = 'Acrelec',
    CLICK_AND_COLLECT = 'Click_And_Collect',
    DELIVERY_IN_APP = 'Delivery_In_App',
    REBOOT = 'Reboot',
    SICOM = 'Sicom',
    TILLSTER = 'Tillster',
}

export enum ComplaintStatus {
    ALL = 'ALL',
    REFUNDED = 'REFUNDED',
    REFUND_VALIDATED = 'REFUND_VALIDATED',
    TO_REFUND = 'TO_REFUND',
    CLOSED = 'CLOSED',
    REFUND_CONFIRMED = 'REFUND_CONFIRMED',
    WAITING_SUPPORT = 'WAITING_SUPPORT',
    WAITING_RESTAURANT = 'WAITING_RESTAURANT',
    WAITING_RESTAURANT_FIRST_REMINDER = 'WAITING_RESTAURANT_FIRST_REMINDER',
    WAITING_RESTAURANT_SECOND_REMINDER = 'WAITING_RESTAURANT_SECOND_REMINDER',
    WAITING_RESTAURANT_LAST_REMINDER = 'WAITING_RESTAURANT_LAST_REMINDER',
    WAITING_CUSTOMER = 'WAITING_CUSTOMER',
    SYSTEM_VALIDATE_REFUND = 'SYSTEM_VALIDATE_REFUND',
    SYSTEM_CONTEST_REFUND = 'SYSTEM_CONTEST_REFUND',
    SYSTEM_CONFIRM_REFUND = 'SYSTEM_CONFIRM_REFUND',
    REFUNDED_BY_CROWN = 'REFUNDED_BY_CROWN',
    REFUNDED_BY_MONEY = 'REFUNDED_BY_MONEY',
    NOTIFY_VALIDATE_REFUND = 'NOTIFY_VALIDATE_REFUND',
    NOTIFY_WAITING_SUPPORT = 'NOTIFY_WAITING_SUPPORT',
    NOTIFY_CONTEST_REFUND = 'NOTIFY_CONTEST_REFUND',
    NOTIFY_CLOSED = 'NOTIFY_CLOSED',
    NOTIFY_CSI = 'NOTIFY_CSI',
    CSI_ERROR = 'CSI_ERROR',
    CSI_REFUNDED = 'CSI_REFUNDED',
    NOTIFY_NEPTING = 'NOTIFY_NEPTING',
    NEPTING_ERROR = 'NEPTING_ERROR',
    NOTIFY_REFUNDED = 'NOTIFY_REFUNDED',
    REFUNDED_MANUALLY = 'REFUNDED_MANUALLY',
    CLOSED_MANUALLY = 'CLOSED_MANUALLY',
    MAX_RETRIED = 'MAX_RETRIED',
}

export enum OrderStatus {
    BUILT = 'BUILT',
    DEBITED = 'DEBITED',
    DEBIT_ERROR = 'DEBIT_ERROR',
    DEBIT_PENDING = 'DEBIT_PENDING',
    FINALIZED = 'FINALIZED',
    MISSING_PRODUCT = 'MISSING_PRODUCT',
    MODIFIED = 'MODIFIED',
    NEPTING_ERROR = 'NEPTING_ERROR',
    NOT_CLICK_COLLECT = 'NOT_CLICK_COLLECT',
    PAID = 'PAID',
    PAID_VALIDATED = 'PAID_VALIDATED',
    PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
    PAYMENT_ERROR = 'PAYMENT_ERROR',
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    PAYMENT_REFUSED = 'PAYMENT_REFUSED',
    PREPARATION_PENDING = 'PREPARATION_PENDING',
    PREPARED_NO_CALLBACK = 'PREPARED_NO_CALLBACK',
    REBOOT_ERROR = 'REBOOT_ERROR',
    REJECTED = 'REJECTED',
    STUART_ERROR = 'STUART_ERROR',
    STUART_JOB_PENDING = 'STUART_JOB_PENDING',
    STUART_JOB_VALIDATED = 'STUART_JOB_VALIDATED',
    SYSTEM_CANCELLED = 'SYSTEM_CANCELLED',
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    USER_CANCELLED = 'USER_CANCELLED',
    VALIDATED = 'VALIDATED',
    VALIDATED_NO_CALLBACK = 'VALIDATED_NO_CALLBACK',
    VALIDATION_PENDING = 'VALIDATION_PENDING',
}

export enum BirthdayStatus {
    ALL = 'ALL',
    STANDBY = 'STANDBY',
    CONFIRMED = 'CONFIRMED',
    REFUSED = 'REFUSED',
}

export enum PickUpType {
    ALL = 'ALL',
    DELIVERY_IN_APP = 'DELIVERY_IN_APP',
    DRIVE = 'DRIVE',
    ON_SITE = 'ON_SITE',
    PARKING = 'PARKING',
    PICK_UP = 'PICK_UP',
    TABLE_SERVICE = 'TABLE_SERVICE',
    TABLE_ORDER = 'TABLE_ORDER',
}

export enum PromoType {
    ITEM_PERCENT_DISCOUNT = 'ITEM_PERCENT_DISCOUNT',
    ITEM_PERCENT_FIXED = 'ITEM_PERCENT_FIXED',
    ITEM_VALUE_DISCOUNT = 'ITEM_VALUE_DISCOUNT',
    ITEM_VALUE_FIXED = 'ITEM_VALUE_FIXED',
    NONE = 'NONE',
    ORDER_PERCENT_DISCOUNT = 'ORDER_PERCENT_DISCOUNT',
    ORDER_PERCENT_FIXED = 'ORDER_PERCENT_FIXED',
    ORDER_VALUE_DISCOUNT = 'ORDER_VALUE_DISCOUNT',
    ORDER_VALUE_FIXED = 'ORDER_VALUE_FIXED',
}

export enum ServiceCode {
    AIR_COND = 'AIR_COND',
    BIRTHDAY = 'BIRTHDAY',
    BREAKFAST = 'BREAKFAST',
    CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
    CLICK_AND_COLLECT_DRIVE = 'CLICK_AND_COLLECT_DRIVE',
    CLICK_AND_COLLECT_ON_SITE = 'CLICK_AND_COLLECT_ON_SITE',
    CLICK_AND_COLLECT_PICKUP_PARKING = 'CLICK_AND_COLLECT_PICKUP_PARKING',
    DELIVERY = 'DELIVERY',
    DELIVERY_DELIVEROO = 'DELIVERY_DELIVEROO',
    DELIVERY_IN_APP = 'DELIVERY_IN_APP',
    DELIVERY_JUSTEAT = 'DELIVERY_JUSTEAT',
    DELIVERY_UBEREATS = 'DELIVERY_UBEREATS',
    DRIVE = 'DRIVE',
    FREE_WIFI = 'FREE_WIFI',
    KINGDOM = 'KINGDOM',
    MEAL_VOUCHER = 'MEAL_VOUCHER',
    NIGHT_OPENING = 'NIGHT_OPENING',
    ON_SITE = 'ON_SITE',
    PARKING = 'PARKING',
    PLAYGROUND = 'PLAYGROUND',
    PRM = 'PRM',
    RESTAURANT = 'RESTAURANT',
    TABLE_ORDER = 'TABLE_ORDER',
    TABLE_ORDER_INDOOR = 'TABLE_ORDER_INDOOR',
    TABLE_ORDER_OUTDOOR = 'TABLE_ORDER_OUTDOOR',
    TABLE_SERVICE = 'TABLE_SERVICE',
    TAKEAWAY = 'TAKEAWAY',
    TERRACE = 'TERRACE',
    WAITING_ON_SITE = 'WAITING_ON_SITE',
    NO_DRIVE_PARKING = 'NO_DRIVE_PARKING',
    APPLICATION = 'APPLICATION',
}

export enum ComplaintType {
    DELIVERY = 'DELIVERY',
    RESTAURANT = 'RESTAURANT',
    CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
}

export enum ComplaintClosureReason {
    ABUSIVE = 'ABUSIVE',
    INCOMPREHENSIBLE = 'INCOMPREHENSIBLE',
    INCOMPLETE = 'INCOMPLETE',
    ALREADY_PROCESSED = 'ALREADY_PROCESSED',
    OTHER = 'OTHER',
}

export enum ComplaintReportReason {
    RESTAURANT_ERROR = 'RESTAURANT_ERROR',
    KINGDOM_ERROR = 'KINGDOM_ERROR',
    DELIVERY_ERROR = 'DELIVERY_ERROR',
    FOOD_POISONING = 'FOOD_POISONING',
    OTHER = 'OTHER',
}

export enum Errors {
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export enum RefundType {
    COUPON = 'COUPON',
    CROWN = 'CROWN',
    MONEY = 'MONEY',
    RESTAURANT_MEETING = 'RESTAURANT_MEETING',
}

export enum Responsibility {
    BK = 'BK',
    CLIENT = 'CLIENT',
    DELIVERY = 'DELIVERY',
    RESTAURANT = 'RESTAURANT',
}

export enum Permissions {
    MANAGE_COMPLAINT = 'MANAGE_COMPLAINT',
    MANAGE_ORDER = 'MANAGE_ORDER',
    MANAGE_PREPARATION_TIME = 'MANAGE_PREPARATION_TIME',
    MANAGE_REFUND = 'MANAGE_REFUND',
    MANAGE_RIGHT = 'MANAGE_RIGHT',
    MANAGE_SERVICE = 'MANAGE_SERVICE',
    RECEIVE_COMPLAINT_MAIL = 'RECEIVE_COMPLAINT_MAIL',
    RECEIVE_REFUND_MAIL = 'RECEIVE_REFUND_MAIL',
    SHOW_COMPLAINT = 'SHOW_COMPLAINT',
    SHOW_ORDER = 'SHOW_ORDER',
    SHOW_PREPARATION_TIME = 'SHOW_PREPARATION_TIME',
    SHOW_SERVICE = 'SHOW_SERVICE',
    MANAGE_TABLE_ORDER_QR_CODES = 'MANAGE_TABLE_ORDER_QR_CODES',
    MANAGE_EMPLOYEES = 'MANAGE_EMPLOYEES',
    SHOW_POWER_BI_DASHBOARD = 'SHOW_POWER_BI_DASHBOARD',
}

export enum Role {
    RESTAURANT = 'RESTAURANT',
    DIRECTOR = 'DIRECTOR',
    ADMINISTRATOR = 'ADMINISTRATOR',
    FRANCHISE = 'FRANCHISE',
    RDM = 'RDM',
}

export enum DayLabel {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum ErrorBirthday {
    SERVICE_INVALID_NUMBER_DAYS = 'SERVICE_INVALID_NUMBER_DAYS',
    SERVICE_OPENING_HOURS_NOT_FILLED = 'SERVICE_OPENING_HOURS_NOT_FILLED',
    SERVICE_OPENING_HOUR_STARTS_BEFORE = 'SERVICE_OPENING_HOUR_STARTS_BEFORE',
    SERVICE_ENDING_HOUR_ENDS_AFTER = 'SERVICE_ENDING_HOUR_ENDS_AFTER',
    SERVICE_BIRTHDAY_DURATION = 'SERVICE_BIRTHDAY_DURATION',
}

export enum FraudScoring {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}
