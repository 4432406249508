import { Cross, Exclamation } from 'assets/icons'
import { Banner, BannerPositionVariant } from 'components/banner/Banner'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H4 } from 'styles/typography'
import { ComplaintStatus, ComplaintType } from 'types/api.enum'

import { ComplaintsTutorialModal } from '../../../components/complaints-tutorial-modal/ComplaintsTutorialModal'
import { useOpenModal } from '../../../hooks/useOpenModal'
import { InfoStamp } from './InfoStamp'

const toRefundStatus = [ComplaintStatus.TO_REFUND, ComplaintStatus.REFUND_CONFIRMED]
const waitingSupportStatus = [
    ComplaintStatus.SYSTEM_VALIDATE_REFUND,
    ComplaintStatus.SYSTEM_CONTEST_REFUND,
    ComplaintStatus.WAITING_SUPPORT,
    ComplaintStatus.REFUND_VALIDATED,
]
const refundConfirmedStatus = [
    ComplaintStatus.SYSTEM_CONFIRM_REFUND,
    ComplaintStatus.REFUNDED_BY_CROWN,
    ComplaintStatus.NOTIFY_CSI,
    ComplaintStatus.NOTIFY_NEPTING,
    ComplaintStatus.NOTIFY_REFUNDED,
    ComplaintStatus.NEPTING_ERROR,
]

const TutorialBanner = () => {
    const { t } = useTranslation()
    const [showBanner, setShowBanner] = useState(true)
    const { isOpen: isOpenModalTutorial, toggleModal: setOpenModalTutorial } = useOpenModal()
    const { complaintType, status } = useCurrentComplaint()

    const getDeliveryText = () => {
        switch (true) {
            case status === ComplaintStatus.WAITING_RESTAURANT:
                return t('complaints.tutorial.banner.delivery.waitingRestaurant')
            case status && toRefundStatus.includes(status):
                return t('complaints.tutorial.banner.delivery.toRefund')
            case status && waitingSupportStatus.includes(status):
                return t('complaints.tutorial.banner.delivery.waitingSupport')
            case status && refundConfirmedStatus.includes(status):
                return t('complaints.tutorial.banner.delivery.refund')
            default:
                setShowBanner(false)
        }
    }

    const getBannerText = () => {
        switch (complaintType) {
            case ComplaintType.DELIVERY:
                return getDeliveryText()
            default:
                return t('complaints.tutorial.banner.restaurant')
        }
    }

    const BannerText = () => (
        <StyledBannerTextContainer>
            <H4 font={fonts.flameSans} color={colors.brown}>
                {getBannerText()}
            </H4>
            <StyledLink onClick={setOpenModalTutorial}>
                <H4 font={fonts.flameSans} color={colors.brown}>
                    {t('complaints.tutorial.banner.link')}
                </H4>
            </StyledLink>
        </StyledBannerTextContainer>
    )

    return (
        <>
            {showBanner && getBannerText() !== undefined && (
                <Banner
                    backgroundColor={colors.white}
                    position={BannerPositionVariant.RELATIVE}
                    borderRadius={BORDER.RADIUS.XS}
                >
                    <StyledIconContainer>
                        <StyledTextContainer>
                            <InfoStamp icon={<Exclamation color={colors.red} />}>
                                <BannerText />
                            </InfoStamp>
                        </StyledTextContainer>
                        <StyledCross size={15} color={colors.blackLight} onClick={() => setShowBanner(false)} />
                    </StyledIconContainer>
                </Banner>
            )}
            {isOpenModalTutorial && <ComplaintsTutorialModal handleClose={setOpenModalTutorial} />}
        </>
    )
}

export default TutorialBanner

const StyledLink = styled.a`
    margin: -${BORDER.WIDTH.XS} 0 0 0;
    text-decoration: underline;
    text-decoration-color: ${colors.brown};
    cursor: pointer;
`

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SPACING.XXS};
`

const StyledIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const StyledCross = styled(Cross)`
    cursor: pointer;
`

const StyledBannerTextContainer = styled.div`
    display: flex;
    gap: ${SPACING.XXXS};
    align-items: center;
    flex-wrap: wrap;
`
