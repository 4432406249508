import {
    Avatar,
    BackCar,
    Box,
    CheckIcon,
    Clock,
    Cross,
    CrownVIP,
    Euro,
    KingTable,
    Motorbike,
    Phone,
    StuartLogo,
    UberDirectLogo,
} from 'assets/icons'
import { Button } from 'components/button/Button'
import { CollapsedOrderCard } from 'components/collapsed-order-card/CollapsedOrderCard'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { Modal } from 'components/modal/Modal'
import { Notif } from 'components/notif/Notif'
import { PickUpTypeStamp } from 'components/pick-up-type-stamp/PickUpTypeStamp'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { selectNumberOfComplaintsbyComplaintStatus } from 'redux/complaint/selectors'
import { setCurrentOrder } from 'redux/order/orderSlice'
import { fetchOrder, integrateOrder } from 'redux/order/thunks'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'
import { OrderDTO } from 'types/api'
import { ComplaintStatus, Permissions, PickUpType } from 'types/api.enum'
import { NotyfType, StatusType } from 'utils/enums'
import {
    canOrderBeReintegrated,
    extractTableNumber,
    formatPrice,
    formatTime,
    getDeliveryPrice,
    getDiffMinuteBetweenDates,
    getOrderStatusType,
} from 'utils/util'

import {
    StyledButtonBottomContainer,
    StyledCardInfo,
    StyledCenter,
    StyledContainer,
    StyledContent,
    StyledH2,
    StyledH4Info,
    StyledHeader,
    StyledIframe,
    StyledInfoContainer,
    StyledInfoLine,
    StyledOrderContainer,
    StyledPickupTypeContainer,
    StyledPriceText,
    StyledSeparator,
    StyledTime,
    StyledTimeContainer,
    StyledTitle,
    StyledTitleText,
    StyledTotal,
    StyledTotalContainer,
} from './style'

interface OrderDetailProps {
    onClose: () => void
    orderId: string
}

const IS_HIDDEN_DELIVERY_PARTNER = true
const IS_STUART_PARTNER = true

export const OrderDetail = ({ onClose, orderId }: OrderDetailProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [time, setTime] = useState(Date.now())
    const currentOrder: OrderDTO = useAppSelector((state) => state.order.currentOrder)
    let currentStatus: StatusType | undefined = undefined
    let clientNameLabeled = ''
    let deliveryTime = 0

    const complaintsSecondReminder = useAppSelector((state) =>
        selectNumberOfComplaintsbyComplaintStatus(state, ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER),
    )

    if (currentOrder) {
        currentStatus = getOrderStatusType(currentOrder)
        clientNameLabeled = currentOrder.clientLastName
            ? currentOrder.clientFirstName + ' ' + currentOrder.clientLastName[0]
            : currentOrder.clientFirstName
        if (currentOrder.dlvPickupETA) {
            const endDate = new Date()
            deliveryTime = getDiffMinuteBetweenDates(endDate, new Date(currentOrder.dlvPickupETA))
        }
    }

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchOrder(orderId))
            // @ts-ignore
            .catch((error: unknown) => setError(error))
            .then(() => setError(null))
    }, [dispatch, orderId])

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 60000)
        return () => {
            clearInterval(interval)
            dispatch(setCurrentOrder(undefined))
        }
    }, [dispatch])

    const integrateOrderClick = () => {
        dispatch(integrateOrder(orderId))
            .then(() => {
                Notif({ type: NotyfType.SUCCESS, text: t('orders.integrateOrder') })
                onClose()
            })
            .catch(() => Notif({ type: NotyfType.ERROR, text: t('error.integrateOrder') }))
    }

    const InfoStamp = ({ icon, text, warning = false }: { icon?: JSX.Element; text?: string; warning?: boolean }) => (
        <StyledInfoLine warning={warning}>
            {icon}
            <StyledH4Info color={colors.brown}>{text}</StyledH4Info>
        </StyledInfoLine>
    )

    const PriceLine = ({ text, price, bold }: { text?: string; price: number; bold?: boolean }) => {
        return (
            <StyledTotal>
                <StyledPriceText color={colors.brown} margin bold={bold}>
                    {text}
                </StyledPriceText>
                <StyledPriceText color={colors.brown} bold={bold}>
                    {formatPrice(price)}
                </StyledPriceText>
            </StyledTotal>
        )
    }

    const TimeRenderer = ({ minutes, completed }: { minutes: number; completed: boolean }) => (
        <H2 color={colors.brown}>{completed ? t('time.noTime') : minutes}</H2>
    )
    const modalStyle = { width: '100%', height: '90%', bottom: 0 }

    if (error) {
        return (
            <Modal handleClose={onClose} center={false} contentStyle={modalStyle}>
                <StyledContainer>
                    <ErrorBlock errorCode={error.response?.status} />
                </StyledContainer>
            </Modal>
        )
    }

    if (!currentOrder) {
        return (
            <Modal handleClose={onClose} center={false} contentStyle={modalStyle}>
                <StyledContainer>
                    <StyledCenter>
                        <ClipLoader size={70} color={colors.orange} loading={true} />
                    </StyledCenter>
                </StyledContainer>
            </Modal>
        )
    }

    const {
        clientPhone,
        clientVIP,
        content,
        createdDate,
        licensePlate,
        carColor,
        deliveryFees,
        driverName,
        driverPhone,
        finalPrice,
        orderNumber,
        originalPrice,
        pickUpType,
        promotions,
        dlvPickupETA,
        dlvDropoffETA,
        deliveryStatus,
        orbStatus,
        trackingDriverUrl,
        pickUpInfo,
    } = currentOrder

    const deliveryPrice = getDeliveryPrice(deliveryFees)
    const isDelivery = pickUpType === PickUpType.DELIVERY_IN_APP
    const isParking = pickUpType === PickUpType.PARKING
    const isKingTable = pickUpType === PickUpType.TABLE_ORDER

    const hasSecondReminderBanner = complaintsSecondReminder > 0

    return (
        <Modal handleClose={onClose} center={false} contentStyle={modalStyle}>
            <StyledContainer>
                <StyledHeader>
                    <StyledTitle>
                        <StyledTimeContainer
                            status={currentStatus || StatusType.ERROR}
                            delivery={!!currentOrder?.dlvPickupETA && deliveryTime >= 0}
                        >
                            {currentStatus === StatusType.ERROR ? (
                                <Cross color={colors.brown} size={33} />
                            ) : !!dlvPickupETA && currentStatus === StatusType.IN_PROGRESS ? (
                                <StyledTime>
                                    <Countdown date={dlvPickupETA} renderer={TimeRenderer} />
                                    <H4 color={colors.brown}>{t('time.minute', { count: deliveryTime })}</H4>
                                </StyledTime>
                            ) : (
                                <CheckIcon color={colors.brown} size={33} />
                            )}
                        </StyledTimeContainer>
                        <StyledTitleText>
                            <StyledH2>
                                {t('orders.titleDetail', {
                                    orderNumber,
                                    clientNameLabeled,
                                })}
                            </StyledH2>
                            {!!orbStatus && <H4 font={fonts.flameSans}>{t(`orders.status.${orbStatus}`)}</H4>}
                        </StyledTitleText>
                    </StyledTitle>
                    <StyledPickupTypeContainer>
                        <PickUpTypeStamp pickUpType={pickUpType} color={colors.brown} />
                    </StyledPickupTypeContainer>
                </StyledHeader>
                <StyledContent
                    status={currentStatus || StatusType.ERROR}
                    additionalMarginBottom={hasSecondReminderBanner}
                >
                    <StyledOrderContainer>
                        {content.map((item) => (
                            <CollapsedOrderCard key={item.id} item={item} />
                        ))}
                        <StyledTotalContainer>
                            <PriceLine
                                text={t('orders.total')}
                                price={isDelivery ? finalPrice - deliveryPrice : originalPrice}
                            />
                            {promotions?.map((promotion) => (
                                <PriceLine
                                    key={promotion.promotionId}
                                    text={promotion.promotionName}
                                    price={finalPrice - originalPrice}
                                />
                            ))}
                            {isDelivery && <PriceLine text={t('orders.deliveryFees')} price={deliveryPrice} />}
                            <StyledSeparator />
                            <PriceLine text={t('orders.finalTotal')} price={finalPrice} bold />
                        </StyledTotalContainer>
                    </StyledOrderContainer>
                    <StyledInfoContainer>
                        <StyledCardInfo>
                            <InfoStamp icon={<Clock color={colors.brown} />} text={formatTime(new Date(createdDate))} />
                            <InfoStamp
                                icon={<Box color={colors.brown} />}
                                text={t('orders.article', {
                                    count: content?.length || 0,
                                })}
                            />
                            <InfoStamp icon={<Euro color={colors.brown} />} text={formatPrice(finalPrice)} />
                        </StyledCardInfo>
                        <StyledCardInfo vip={clientVIP}>
                            <InfoStamp icon={<Avatar color={colors.brown} />} text={clientNameLabeled} />
                            <InfoStamp icon={<Phone color={colors.brown} />} text={clientPhone} />
                            {isParking && licensePlate && carColor && (
                                <InfoStamp
                                    icon={<BackCar color={colors.brown} />}
                                    text={licensePlate + ' - ' + carColor}
                                />
                            )}
                            {currentOrder?.clientVIP && (
                                <InfoStamp icon={<CrownVIP color={colors.orange} />} text={t('orders.vipId')} warning />
                            )}
                            {isKingTable && pickUpInfo && (
                                <InfoStamp
                                    icon={<KingTable color={colors.brown} />}
                                    text={'Table ' + extractTableNumber(pickUpInfo)}
                                />
                            )}
                        </StyledCardInfo>
                        {isDelivery && (
                            <StyledCardInfo>
                                {!!driverName && (
                                    <InfoStamp icon={<Motorbike color={colors.brown} />} text={driverName} />
                                )}
                                {!!deliveryStatus && (
                                    <InfoStamp text={t(`orders.deliveryStatus.${deliveryStatus}`)} warning />
                                )}
                                {!!driverPhone && (
                                    <InfoStamp icon={<Phone color={colors.brown} />} text={driverPhone} />
                                )}
                                {!!dlvPickupETA && (
                                    <InfoStamp
                                        text={t('orders.pickUpTime') + formatTime(new Date(dlvPickupETA))}
                                        warning
                                    />
                                )}
                                {!!dlvDropoffETA && (
                                    <div style={{ marginTop: 5 }}>
                                        <InfoStamp
                                            text={t('orders.arrivalTime') + formatTime(new Date(dlvDropoffETA))}
                                            warning
                                        />
                                    </div>
                                )}
                            </StyledCardInfo>
                        )}
                        {!IS_HIDDEN_DELIVERY_PARTNER && (
                            <StyledCardInfo>
                                {IS_STUART_PARTNER ? (
                                    <StuartLogo width={105} height={26} />
                                ) : (
                                    <UberDirectLogo width={95} height={27} />
                                )}
                                <InfoStamp
                                    text={
                                        t('orders.deliveryPartner') + (IS_STUART_PARTNER ? ' Stuart' : ' Uber Direct')
                                    }
                                />
                            </StyledCardInfo>
                        )}
                        {trackingDriverUrl && (
                            <StyledCardInfo>
                                <StyledIframe src={trackingDriverUrl} />
                            </StyledCardInfo>
                        )}
                    </StyledInfoContainer>
                </StyledContent>
                <ShouldDisplay
                    permission={Permissions.MANAGE_ORDER}
                    condition={canOrderBeReintegrated(currentOrder, time)}
                >
                    <StyledButtonBottomContainer additionalMarginBottom={hasSecondReminderBanner}>
                        <Button
                            text={t('component.button.integrateOrder')}
                            color={colors.red}
                            outlined
                            onClick={integrateOrderClick}
                        />
                    </StyledButtonBottomContainer>
                </ShouldDisplay>
            </StyledContainer>
        </Modal>
    )
}
