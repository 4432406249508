import styled from 'styled-components'

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    width: 100%;
    margin-left: 95px;
    @media (orientation: landscape) {
        height: 100vh;
    }
    @media (orientation: portrait) {
        height: fit-content;
    }
`

export const StyledContentContainer = styled.div`
    padding: 24px;
    height: 100%;
    overflow: scroll;
`
